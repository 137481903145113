function Loader() {
    return (
        <div className="page-loader-wrapper">
            <div className="loader">
                <div className="m-t-30"><image src="assets/images/logo.svg" width="48" height="48" alt="Alpino" /></div>
                <p>Please wait...</p>
            </div>
        </div>
    );
}

export default Loader;