//Core components
import axios from "axios";
import React, { useEffect, useState } from "react";

//Global constants
import {
    TOKEN,
    GET_USERS,
    CREATE_RECRUITS
} from "../Constants";


function RecruitsCreate(props) {

    const { closeModalBtn, setAlert, setAlertMessage } = props;
    const [getUsers, createUsers] = useState(null);

    const axiosInstance = axios.create({
        headers: {
            'Authorization': `Bearer ${TOKEN}`,
            'Content-Type': 'application/json',
        },
    });


    useEffect(() => {
        axiosInstance.get(GET_USERS)
            .then((response) => {
                createUsers(response.data);
                console.log(response.data);
            })
            .catch((error) => {
                console.error(error);
            });
    }, []);

    const [getFormData, setFormData] = useState({
        user_id: '',
        recruit_id: ''
    });

    const handleFormChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...getFormData,
            [name]: value
        });
    }

    const handleFormSubmit = (e) => {
        e.preventDefault();
        const { user_id, recruit_id } = getFormData;

        axiosInstance.post(CREATE_RECRUITS, {
            user_id: user_id,
            recruit_id: recruit_id
        })
            .then((response) => {
                setAlertMessage(response.data);
                setAlert(true);
                closeModalBtn();
                console.log("Successfully sent " + JSON.stringify(response.data));
            })
            .catch((error) => {
                setAlertMessage("There was an error in creating record please try again!");
                closeModalBtn();
                console.error("Error creating leave: ", error);
            })
    }


    return (
        <div>

            <div className="modal-overlay"></div>
            <div className="modal fade show" style={{ display: 'block' }}>
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Recruit Application Form</h5>
                            <button type="button" className="close" onClick={closeModalBtn}>
                                <span>&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <form onSubmit={handleFormSubmit}>
                                <div>
                                    <label>Recruiter</label>
                                    <select
                                        className="form-control"
                                        name="user_id"
                                        onChange={handleFormChange}
                                    >
                                        <option value="">Select user</option>
                                        {getUsers ? (
                                            getUsers.map((user, index) => (
                                                <option key={index} value={user.id}>{user.name}</option>
                                            ))
                                        ) : null}
                                    </select>
                                </div>
                                <div>
                                    <label>Recruit</label>
                                    <select
                                        className="form-control"
                                        name="recruit_id"
                                        onChange={handleFormChange}
                                    >
                                        <option value="">Select user</option>
                                        {getUsers ? (
                                            getUsers.map((user, index) => (
                                                <option key={index} value={user.id}>{user.name}</option>
                                            ))
                                        ) : null}
                                    </select>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" onClick={closeModalBtn}>
                                        Close
                                    </button>
                                    <button type="submit" className="btn btn-primary">
                                        Save changes
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default RecruitsCreate;