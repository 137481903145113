import React from "react";
import Nav from "./Nav";
import Sidebar from "./Sidebar";
import Loader from "./Loader";

function Home() {

    return (
        <div>
            <Loader />
            <Nav />
            <Sidebar />
        </div>
    );
}

export default Home;