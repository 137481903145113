//Core components
import axios from "axios";
import React, { useEffect, useState } from "react";

//Project components
import Alert from "../components/Alert";
import RecruitsCreate from "../components/forms/RecruitsCreate";

//External css and js
import 'datatables.net-dt';
import "../components/css/multi-select.css";
import "bootstrap-select/dist/css/bootstrap-select.min.css";

//Global constants
import {
    TOKEN,
    TOKEN_KEY,
    GET_RECRUITS,
} from "../components/Constants";

function Recruits() {

    const [showModal, setShowModal] = useState(false);
    const [recruitData, setrecruitData] = useState(null);
    const [createRecruitStatus, setcreateRecruitStatus] = useState('');
    const [createRecruitResponse, setcreateRecruitResponse] = useState(null);

    //Check if user is logged in
    const token = localStorage.getItem(TOKEN_KEY);

    if (!token) {
        window.location.href = "/login";
    }

    useEffect(() => {
        const recruits_url = GET_RECRUITS;

        //Get loan form registered in the app
        const axiosInstance = axios.create({
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
        });

        axiosInstance.get(recruits_url)
            .then((response) => {
                setrecruitData(response.data);
                console.log(JSON.stringify(response.data));
            })
            .catch((error) => {
                console.error('Error:', error);
            });

    }, []);

    const openModal = () => {
        setShowModal(true);
        //console.log("Cliked " + showModal);
    };

    const closeModal = () => {
        setShowModal(false);
    };

    const setAlertState = (stateBool) => {
        setcreateRecruitStatus(stateBool);
    };

    const setAlertMessageFn = (alertMessage) => {
        setcreateRecruitResponse(alertMessage);
    };

    const tableScroll = { overflowX: 'auto', };

    return (
        <section className="content inbox">
            <div className="container-fluid">
                <div className="block-header">
                    <div className="row clearfix">
                        <div className="col-lg-5 col-md-5 col-sm-12">
                            <h2>Inbox</h2>
                            <ul className="breadcrumb padding-0">
                                <li className="breadcrumb-item"><a href="index.html"><i className="zmdi zmdi-home"></i></a></li>
                                <li className="breadcrumb-item active">Inbox</li>
                            </ul>
                        </div>
                        <div className="col-lg-7 col-md-7 col-sm-12">
                            <div className="input-group m-b-0">
                                <input type="text" className="form-control" placeholder="Search..." />
                                <span className="input-group-addon"><i className="zmdi zmdi-search"></i></span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="clearfix">
                    <div className="row clearfix card">
                        {createRecruitStatus !== "" ? <Alert alertState={createRecruitStatus} message={createRecruitResponse} /> : null}
                        <div className="col-md-12 col-lg-12" style={tableScroll}>
                            <div className="body">
                                <h2>Your team </h2>
                                <button className="btn btn-primary btn-round" onClick={openModal}>Create</button>

                                {showModal ? (
                                    <RecruitsCreate closeModalBtn={closeModal} setAlert={setAlertState} setAlertMessage={setAlertMessageFn} />
                                ) : null}
                                <table className="table table-bordered table-striped table-hover dataTable js-exportable">
                                    <thead>
                                        <tr>
                                            <th>Name</th>
                                            <th>Recruit Name</th>
                                            <th>Current Level</th>
                                            <th>Date</th>
                                        </tr>
                                    </thead>
                                    <tfoot>
                                        <tr>
                                            <th>Name</th>
                                            <th>Recruit Name</th>
                                            <th>Current Level</th>
                                            <th>Date</th>
                                        </tr>
                                    </tfoot>
                                    <tbody>
                                        {recruitData ? (
                                            recruitData.map((recruit) => (
                                                <tr>
                                                    <td>{recruit.user.name + " " + recruit.user.surname}</td>
                                                    <td>{recruit.recruit.name ?? "N/A"}</td>
                                                    <td>{recruit.level ? recruit.level : "N/A"}</td>
                                                    <td>{recruit.created_at ?? "N/A"}</td>
                                                </tr>
                                            ))
                                        ) :

                                            <tr>
                                                <td colSpan={6} align="center">No data to display</td>
                                            </tr>
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Recruits;