//Core components
import axios from "axios";
import React, { useEffect, useState } from "react";

//Project components
import Alert from "../components/Alert";
import LevelsCreate from "../components/forms/LevelsCreate";

//External css and js
import 'datatables.net-dt';
import "../components/css/multi-select.css";
import "bootstrap-select/dist/css/bootstrap-select.min.css";

//Global constants
import {
    TOKEN,
    TOKEN_KEY,
    GET_LEVELS,
} from "../components/Constants";

function Levels() {

    const [levelsData, setLevelsData] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [createLevelsSuccess, setCreateLevelsSuccess] = useState('');
    const [createLevelsResponse, setCreateLevelsResponse] = useState(null);

    //Check if user is logged in
    const token = localStorage.getItem(TOKEN_KEY);

    if (!token) {
        window.location.href = "/login";
    }

    useEffect(() => {
        const levels__url = GET_LEVELS;

        //Get leave form registered in the app
        const axiosInstance = axios.create({
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
        });

        axiosInstance.get(levels__url)
            .then((response) => {
                setLevelsData(response.data);
                console.log(JSON.stringify(response.data));
            })
            .catch((error) => {
                console.error('Error:', error);
            });

    }, []);

    const openModal = () => {
        setShowModal(true);
        //console.log("Cliked " + showModal);
    };

    const closeModal = () => {
        setShowModal(false);
    };

    const setAlertState = (stateBool) => {
        setCreateLevelsSuccess(stateBool);
    };

    const setAlertMessageFn = (alertMessage) => {
        setCreateLevelsResponse(alertMessage);
    };

    const btnWidth = { width: "8%" };

    return (
        <section className="content">
            <div className="container-fluid">
                <div className="row clearfix">
                    <div className="col-lg-12">
                        {createLevelsSuccess !== "" ? <Alert alertState={createLevelsSuccess} message={createLevelsResponse} /> : null}
                        <div className="card">
                            <div className="header">
                            </div>
                            <div className="body">
                                <h2><strong>Levels</strong> applications </h2>
                                <button className="btn btn-primary btn-round" style={btnWidth} onClick={openModal}>Create</button>

                                {showModal ? (
                                    <LevelsCreate closeModalBtn={closeModal} setAlert={setAlertState} setAlertMessage={setAlertMessageFn} />
                                ) : null}
                                <table className="table table-bordered table-striped table-hover dataTable js-exportable">
                                    <thead>
                                        <tr>
                                            <th>Name</th>
                                            <th>Percentage</th>
                                            <th>Date registered on system</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tfoot>
                                        <tr>
                                            <th>Name</th>
                                            <th>Percentage</th>
                                            <th>Date registered on system</th>
                                            <th>Action</th>
                                        </tr>
                                    </tfoot>
                                    <tbody>
                                        {levelsData ? (
                                            levelsData.map((levelItem) => (
                                                <tr>
                                                    <td>{levelItem.name}</td>
                                                    <td>{levelItem.percentage}</td>
                                                    <td>{levelItem.created_at}</td>
                                                    <td>Delete</td>
                                                </tr>
                                            ))
                                        ) :
                                            <tr>
                                                <td colSpan={6} align="center">No data to display</td>
                                            </tr>
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Levels;