//Main react components
import axios from "axios";
import React, { useState } from "react";
import { Link } from "react-router-dom";

//Project components
import {
    NAME,
    EMAIL,
    LOGIN,
    COMPANY,
    TOKEN_KEY
} from "../../components/Constants";
import logo from "../../components/images/logo.png";

function Login() {

    const [getError, setError] = useState('');
    const [getFormData, setFormData] = useState({
        email: '',
        password: ''
    });

    const token = localStorage.getItem(TOKEN_KEY);

    if (token) {
        window.location.href = "/dashboard";
    }

    const handleFormChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...getFormData,
            [name]: value
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const { email, password } = getFormData;
        console.log(email + " " + password);

        axios.post(LOGIN, {
            email: email,
            password: password
        })
            .then((response) => {
                console.log("Response " + response.data);
                if (response.data.error) {
                    setError(response.data.error);
                } else {
                    localStorage.setItem(TOKEN_KEY, response.data.token);
                    localStorage.setItem(EMAIL, response.data.user.email);
                    localStorage.setItem(NAME, response.data.user.name);
                    window.location.href = "/dashboard";
                }
            })
            .catch((error) => {
                console.log("There was an error");
                console.error("Erro " + error);
            });
    }

    const logoWidth = {
        width: "30%",
    };

    return (
        <div className="authentication">
            <div className="container">
                <div className="col-md-12 content-center">
                    <div className="row">
                        {getError ?
                            <div class="alert alert-danger">
                                <strong>Error!</strong> {getError}
                            </div>
                            : null
                        }
                        <div className="col-lg-6 col-md-12">
                            <div className="company_detail">
                                <div>
                                    <img src={logo} alt="Logo" style={logoWidth} />
                                    <h6 className="logo">{COMPANY} </h6>
                                </div>
                                <div className="footer">
                                    {/* <ul className="social_link list-unstyled">
                                        <li><a href="https://thememakker.com" title="ThemeMakker"><i className="zmdi zmdi-globe"></i></a></li>
                                        <li><a href="https://themeforest.net/user/thememakker" title="Themeforest"><i className="zmdi zmdi-shield-check"></i></a></li>
                                        <li><a href="https://www.linkedin.com/company/thememakker/" title="LinkedIn"><i className="zmdi zmdi-linkedin"></i></a></li>
                                        <li><a href="https://www.facebook.com/thememakkerteam" title="Facebook"><i className="zmdi zmdi-facebook"></i></a></li>
                                        <li><a href="http://twitter.com/thememakker" title="Twitter"><i className="zmdi zmdi-twitter"></i></a></li>
                                        <li><a href="http://plus.google.com/+thememakker" title="Google plus"><i className="zmdi zmdi-google-plus"></i></a></li>
                                        <li><a href="https://www.behance.net/thememakker" title="Behance"><i className="zmdi zmdi-behance"></i></a></li>
                                    </ul> */}
                                    <hr />
                                    <ul>
                                        <li><a href="https://spectroncreative.co.zw/projects/granite/" target="_blank">Website</a></li>
                                        <li><a href="https://spectroncreative.co.zw/projects/granite/contact-us/" target="_blank">Contact Us</a></li>
                                        <li><a href="https://spectroncreative.co.zw/projects/granite/about/" target="_blank">About Us</a></li>
                                        <li><a href="https://spectroncreative.co.zw/projects/granite/tombstones/" target="_blank">Services</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-5 col-md-12 offset-lg-1">
                            <div className="card-plain">
                                <div className="header">
                                    <h5>Log in</h5>
                                </div>
                                <form className="form" onSubmit={handleSubmit}>
                                    <div className="input-group">
                                        <input type="email" className="form-control" name="email" onChange={handleFormChange} placeholder="Email" />
                                        <span className="input-group-addon"><i className="zmdi zmdi-account-circle"></i></span>
                                    </div>
                                    <div className="input-group">
                                        <input type="password" onChange={handleFormChange} name="password" placeholder="Password" className="form-control" />
                                        <span className="input-group-addon"><i className="zmdi zmdi-lock"></i></span>
                                    </div>
                                    <div className="footer">
                                        <button type="submit" className="btn btn-primary btn-round btn-block">SIGN IN</button>
                                        <Link to="/register" className="btn btn-primary btn-simple btn-round btn-block">SIGN UP</Link>
                                    </div>
                                </form>
                                <a href="forgot-password.html" className="link">Forgot Password?</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Login;