//Site variables
//const DOMAIN = "http://localhost:8000";
const DOMAIN = "https://backend.granite.co.zw";
export const TOKEN_KEY = "tombstone-auth-key";
export const EMAIL = "tombstone-email";
export const NAME = "tombstone-name";
export const TOKEN = "2|Raf16JasAqGOsRBPZPX2XB7Nk3MO1wV7sZ2DHjHS20b70445";
export const COMPANY = "Granite Tombstones Association";
export const CONFIRM_PASSWORD_ERROR = "Passwords do not match";

//Levels endpoints
export const GET_USERS = DOMAIN + "/api/users";
export const LOGIN = DOMAIN + "/api/users/login";
export const CREATE_USERS = DOMAIN + "/api/users/create";

//Levels endpoints
export const GET_LEVELS = DOMAIN + "/api/levels";
export const CREATE_LEVELS = DOMAIN + "/api/levels/create";

//Users endpoints
export const GET_PACKAGE = DOMAIN + "/api/paymentpackage";
export const GET_PACKAGE_NAMES = DOMAIN + "/api/package/names";
export const CREATE_PACKAGE = DOMAIN + "/api/paymentpackage/create";

//Recruits endpoints
export const GET_RECRUITS = DOMAIN + "/api/recruits";
export const CREATE_RECRUITS = DOMAIN + "/api/recruits/create";

//Loan endpoints
export const GET_LOAN = DOMAIN + "/api/loan";
export const SET_LOAN = DOMAIN + "/api/loan/create";

//Options endpoints
export const GET_CURRENCY = DOMAIN + "/api/object/get/currency";

//Operations endpoints
export const GET_OPERATIONS = DOMAIN + "/api/operations";
export const GET_OPERATIONS_PARENT = DOMAIN + "/api/operations/parent";