//React main components
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import React, { useState, useEffect } from "react";

//Project components
import Alert from "../../components/Alert";
import {
    NAME,
    EMAIL,
    TOKEN_KEY,
    CREATE_USERS,
    CONFIRM_PASSWORD_ERROR,
} from "../../components/Constants";

function Register() {

    const [getPasswordConfirm, setPasswordConfirm] = useState(true);
    const [getUserCreatedStatus, setUserCreatedStatus] = useState(true);
    const [getUserCreatedError, setUserCreatedError] = useState(true);

    const [getFormData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        password: '',
        confirm_password: ''
    });

    const navigate = useNavigate();

    const token = localStorage.getItem(TOKEN_KEY);

    if (token) {
        navigate("/dashboard");
    }

    // const axiosInstance = axios.create({
    //     headers: {
    //         'Authorization': `Bearer ${TOKEN}`,
    //         'Content-Type': 'application/json',
    //     },
    // });

    const handleFormChange = (e) => {
        const { name, value } = e.target;
        const { password } = getFormData;

        if (name == "confirm_password") {
            password === value ? setPasswordConfirm(true) : setPasswordConfirm(false);
        }

        setFormData({
            ...getFormData,
            [name]: value
        });
    }

    const handleFormSubmit = (e) => {
        e.preventDefault();
        const { name, email, phone, password } = getFormData;

        axios.post(CREATE_USERS, {
            name: name,
            email: email,
            phonenumber: phone,
            password: password
        }).then((response) => {
            setUserCreatedStatus(true);
            console.log(response.data);
            localStorage.setItem(TOKEN_KEY, response.data.token); //Get the token and set it on the localstorage
            localStorage.setItem(EMAIL, response.data.user.email);
            localStorage.setItem(NAME, response.data.user.name);

            window.location.href = '/login';
        }).catch((error) => {
            setUserCreatedStatus(false);
            setUserCreatedError(error.message);
            console.error(error);
            console.log("Error " + JSON.stringify(error.resonse.data.errors.email));
        })

        console.log(name + " " + email + " " + phone + " " + password);
    }

    return (
        <div className="authentication">
            <div className="container">
                <div className="col-md-12 content-center">
                    {getPasswordConfirm ? null : <Alert alertState={getPasswordConfirm} message={CONFIRM_PASSWORD_ERROR} />}
                    {getUserCreatedStatus ? null : <Alert alertState={getUserCreatedStatus} message={getUserCreatedError} />}
                    <div className="row">
                        <div className="col-lg-6 col-md-12">
                            <div className="company_detail">
                                <h4 className="logo"><img src="assets/images/logo.svg" alt="" /> Alpino</h4>
                                <h3>The ultimate <strong>Bootstrap 4</strong> Admin Dashboard</h3>
                                <p>Alpino is fully based on HTML5 + CSS3 Standards. Is fully responsive and clean on every device and every browser</p>
                                <div className="footer">
                                    <ul className="social_link list-unstyled">
                                        <li><a href="https://thememakker.com" title="ThemeMakker"><i className="zmdi zmdi-globe"></i></a></li>
                                        <li><a href="https://themeforest.net/user/thememakker" title="Themeforest"><i className="zmdi zmdi-shield-check"></i></a></li>
                                        <li><a href="https://www.linkedin.com/company/thememakker/" title="LinkedIn"><i className="zmdi zmdi-linkedin"></i></a></li>
                                        <li><a href="https://www.facebook.com/thememakkerteam" title="Facebook"><i className="zmdi zmdi-facebook"></i></a></li>
                                        <li><a href="http://twitter.com/thememakker" title="Twitter"><i className="zmdi zmdi-twitter"></i></a></li>
                                        <li><a href="http://plus.google.com/+thememakker" title="Google plus"><i className="zmdi zmdi-google-plus"></i></a></li>
                                        <li><a href="https://www.behance.net/thememakker" title="Behance"><i className="zmdi zmdi-behance"></i></a></li>
                                    </ul>
                                    <hr />
                                    <ul>
                                        <li><a href="http://thememakker.com/contact/" target="_blank">Contact Us</a></li>
                                        <li><a href="http://thememakker.com/about/" target="_blank">About Us</a></li>
                                        <li><a href="http://thememakker.com/services/" target="_blank">Services</a></li>
                                        <li><a href="javascript:void(0);">FAQ</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-5 col-md-12 offset-lg-1">
                            <div className="card-plain">
                                <div className="header">
                                    <h5>Sign Up</h5>
                                    <span>Register a new membership</span>
                                </div>
                                <form className="form" onSubmit={handleFormSubmit}>
                                    <div className="input-group">
                                        <input type="text" name="name" onChange={handleFormChange} className="form-control" placeholder="Enter User Name" />
                                        <span className="input-group-addon"><i className="zmdi zmdi-account-circle"></i></span>
                                    </div>
                                    <div className="input-group">
                                        <input type="email" name="email" onChange={handleFormChange} className="form-control" placeholder="Enter Email" />
                                        <span className="input-group-addon"><i className="zmdi zmdi-email"></i></span>
                                    </div>
                                    <div className="input-group">
                                        <input type="text" name="phone" onChange={handleFormChange} className="form-control" placeholder="Enter Phone Number" />
                                        <span className="input-group-addon"><i className="zmdi zmdi-phone"></i></span>
                                    </div>
                                    <div className="input-group">
                                        <input type="password" name="password" onChange={handleFormChange} placeholder="Password" className="form-control" />
                                        <span className="input-group-addon"><i className="zmdi zmdi-lock"></i></span>
                                    </div>
                                    <div className="input-group">
                                        <input type="password" onChange={handleFormChange} name="confirm_password" placeholder="Confirm Password" className="form-control" />
                                        <span className="input-group-addon"><i className="zmdi zmdi-lock"></i></span>
                                    </div>
                                    <div className="footer">
                                        <button type="submit" className="btn btn-primary btn-round btn-block">SIGN UP</button>
                                    </div>
                                </form>
                                <Link className="link" to="/login">You already have a membership?</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Register;