//Core components
import axios from "axios";
import React, { useEffect, useState } from "react";

//Project components
import Alert from "../components/Alert";
import PaymentPackageCreate from "../components/forms/PaymentPackageCreate";

//External css and js
import 'datatables.net-dt';
import "../components/css/multi-select.css";
import "bootstrap-select/dist/css/bootstrap-select.min.css";

//Global constants
import {
    TOKEN,
    TOKEN_KEY,
    GET_PACKAGE,
} from "../components/Constants";

function PaymentPackage() {

    const [packageData, setPackageData] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [createPackageSuccess, setCreatePackageSuccess] = useState('');
    const [createPackageResponse, setCreatePackageResponse] = useState(null);

    //Check if user is logged in
    const token = localStorage.getItem(TOKEN_KEY);

    if (!token) {
        window.location.href = "/login";
    }

    useEffect(() => {
        const package_url = GET_PACKAGE;

        //Get loan form registered in the app
        const axiosInstance = axios.create({
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
        });

        axiosInstance.get(package_url)
            .then((response) => {
                setPackageData(response.data);
                console.log(JSON.stringify(response.data));
            })
            .catch((error) => {
                console.error('Error:', error);
            });

    }, []);

    const openModal = () => {
        setShowModal(true);
        //console.log("Cliked " + showModal);
    };

    const closeModal = () => {
        setShowModal(false);
    };

    const setAlertState = (stateBool) => {
        setCreatePackageSuccess(stateBool);
    };

    const setAlertMessageFn = (alertMessage) => {
        setCreatePackageResponse(alertMessage);
    };

    const btnWidth = { width: "8%" };

    return (
        <section className="content">
            <div className="container-fluid">
                <div className="row clearfix">
                    <div className="col-lg-12">
                        {createPackageSuccess !== "" ? <Alert alertState={createPackageSuccess} message={createPackageResponse} /> : null}
                        <div className="card">
                            <div className="header">
                            </div>
                            <div className="body">
                                <h2><strong>Payment Package</strong> applications </h2>
                                <button className="btn btn-primary btn-round" style={btnWidth} onClick={openModal}>Create</button>

                                {showModal ? (
                                    <PaymentPackageCreate closeModalBtn={closeModal} setAlert={setAlertState} setAlertMessage={setAlertMessageFn} />
                                ) : null}
                                <table className="table table-bordered table-striped table-hover dataTable js-exportable">
                                    <thead>
                                        <tr>
                                            <th>Name</th>
                                            <th>Minimum age</th>
                                            <th>Maximum Age</th>
                                            <th>Price</th>
                                            <th>Benefits</th>
                                            <th>Loan created date</th>
                                        </tr>
                                    </thead>
                                    <tfoot>
                                        <tr>
                                            <th>Name</th>
                                            <th>Minimum age</th>
                                            <th>Maximum Age</th>
                                            <th>Price</th>
                                            <th>Benefits</th>
                                            <th>Loan created date</th>
                                        </tr>
                                    </tfoot>
                                    <tbody>
                                        {packageData ? (
                                            packageData.map((loan, index) => (
                                                <tr key={index}>
                                                    <td>{loan.package_name.name}</td>
                                                    <td>{loan.min_age ?? "N/A"}</td>
                                                    <td>{loan.max_age ?? "N/A"}</td>
                                                    <td>{loan.price ?? "N/A"}</td>
                                                    <td>{loan.benefits ?? "N/A"}</td>
                                                    <td>{loan.created_at ?? "N/A"}</td>
                                                </tr>
                                            ))
                                        ) :

                                            <tr>
                                                <td colSpan={6} align="center">No data to display</td>
                                            </tr>
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default PaymentPackage;