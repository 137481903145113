//Core components
import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';

//Project components
import PaymentPackage from './pages/PaymentPackage';
import Levels from './pages/Levels';
import Home from './components/Home';
import Recruits from './pages/Recruits';
import Dashboard from './pages/Dashboard';
import Login from './pages/auth/Login';
import Register from './pages/auth/Register';
import Logout from './pages/auth/Logout';

//External style and js components
import 'bootstrap/dist/css/bootstrap.min.css';
import 'material-design-iconic-font/dist/css/material-design-iconic-font.min.css';

import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from "react-router-dom";

const pages = (
  <Route path="/" element={<Home />} exact>
    <Route path="/dashboard" element={<Dashboard />} />
    <Route path="/levels" element={<Levels />} />
    <Route path="/paymentpackage" element={<PaymentPackage />} />
    <Route path="/recruits" element={<Recruits />} />
  </Route>
);

const auth = (
  <Route>
    <Route path="/login" element={<Login />} />
    <Route path="/register" element={<Register />} />
    <Route path="/logout" element={<Logout />} />
  </Route>
);

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route>
      {pages}
      {auth}
    </Route>
  )
);

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
